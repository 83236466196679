function Classes() {
	return (
		<div style={{padding: '20px'}}>
		<h1>Classes</h1>
		<h2>JavaScript 101</h2>
		<p>
		Commons 6, Mondays and Fridays from 6:30 to 7:15pm.
		This class aims to teach the fundamentals of programming through
		the ProcessingJS graphics library. It is intended both for 
		current coding club members and for those who are new to computer science.
		Course material can be found here: <a href="https://github.com/Christendom-College-Coding-Club/javascript-101-course-materials">JavaScript 101 course materials</a>.
		</p>
		</div>
	)
}

export default Classes;
