import { useRef } from 'react'
import './Feedback.css'

function Feedback() {
	const feedbackRef = useRef(null)
	const nameRef = useRef(null)

	async function handleSubmit() { 
		const message = 
			{
				name: nameRef.current.value,
				feedback: feedbackRef.current.value
			}
		fetch("/api/feedback/",
			{
				method: "POST",
				headers: {
					"Content-Type":
					"application/json"
				},
				body: JSON.stringify(message)
			}
		)
	}

	return (
		<div>
		<form id="feedback-form" onSubmit={handleSubmit}>

		<label>
		<p>
		Your Name (optional):
		</p>
		<input id="name-input" ref={nameRef} type="text" size="30" maxlength="30" />
		</label>

		<label>
		<p>
		Your Feedback:
		</p>
		<textarea id="feedback-input" ref={feedbackRef} type="text" size="2000" required />
		</label>

		<p>
		Example: "I like how Christendom Typesetter makes footnotes 
		super organized, but I hate the color scheme, and I found a glitch with
		bibliographies."
		</p>


		<div>
		<button type='submit' >Submit</button>
		</div>

		</form>
		</div>
	)
}

export default Feedback
